<template>
  <v-layout class="column sheet-form">
    <div class="form-main-header">
      <h1>
        {{ formName }}
      </h1>
      <v-divider />
    </div>
    <slot name="top" />
    <v-form ref="companyTerm" class="company-term">
      <div class="inner-container">
        <CRInput
          id="company-terms-form-input-name"
          v-model="model.label"
          :floatinglabel="'Terms Name'"
          :disabled="status"
        />

        Terms
        <v-textarea
          id="company-terms-form-input-body"
          v-model="model.body"
          class="pt-2"
          rows="10"
          flat
          solo
          small
          :disabled="status"
        />

        <v-checkbox
          id="company-terms-form-input-default"
          v-model="model.isDefault"
          class="justify-center"
          :label="`Default terms?`"
          :disabled="status"
        />

        <div class="button-container">
          <v-btn
            id="company-terms-form-button-cancel"
            class="btn-secondaryaction"
            @click="cancelAction"
          >
            {{ isModeView ? 'All Company Terms' : 'Cancel' }}
          </v-btn>

          <v-btn
            v-if="isModeEdit"
            id="company-terms-form-button-save"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Save Terms
          </v-btn>

          <v-btn
            v-if="isModeAdd"
            id="company-terms-form-button-add"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Add Terms
          </v-btn>

          <v-btn
            v-if="isModeView"
            id="company-terms-form-edit"
            class="btn-primaryaction"
            @click="enableEdit"
          >
            Edit Terms
          </v-btn>
        </div>
      </div>
    </v-form>
  </v-layout>
</template>
<script>
import companyTerms from '@/services/companyTerms'
import { authComputed } from '@/state/helpers'
import * as logger from '@/utils/logger'

export default {
  props: {
    id: {
      type: String,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => 'view',
    },
  },
  data() {
    return {
      model: {
        companyTermId: '',
        label: '',
        body: '',
        isDefault: false,
      },
      status: true,
      showOptions: false,
      loading: false,
    }
  },
  computed: {
    ...authComputed,
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    formName() {
      if (this.isModeEdit) {
        return 'Edit Company Terms'
      }
      if (this.isModeView) {
        return 'View Company Terms'
      }

      return 'Company Terms'
    },
  },
  async mounted() {
    if (this.isModeView || this.isModeEdit) {
      const companyTermResponse = await companyTerms.getCompanyTerm(this.id)
      const companyTerm = companyTermResponse.data.companyTerm

      this.model.companyTermId = this.id
      this.model.label = companyTerm.label
      this.model.body = companyTerm.body
      this.model.isDefault = companyTerm.isDefault
    }
    if (this.isModeEdit || this.isModeAdd) {
      this.status = false
    }
  },
  methods: {
    placeSelected(evt) {
      this.model.address = evt.place
    },
    focus() {
      this.showOptions = true
    },
    enableEdit() {
      this.mode = 'edit'
      this.status = false
      this.$router.push(`/companyTerms/edit/${this.id}`)
    },
    async submit() {
      let id
      this.loading = true

      if (this.isModeAdd) {
        this.model.isActive = true
        this.model.companyId = this.currentUser.companyId
      }

      const payload = JSON.parse(JSON.stringify(this.model))

      try {
        if (this.isModeAdd) {
          id = await companyTerms
            .createCompanyTerms(payload)
            .then((data) => data.data)
          this.$store.dispatch(
            'app/showAlert',
            { message: 'Terms have been created.' },
            { root: true }
          )
        } else if (this.isModeEdit) {
          id = await companyTerms
            .editCompanyTerms(payload)
            .then((data) => data.data)
        }
      } catch (e) {
        logger.error(e)
      }

      this.loading = false
      this.status = true
      this.$router.push(`/company-terms`)
    },
    backToTable() {
      this.$router.push('/company-terms')
    },
    cancelAction() {
      this.backToTable()
    },
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  justify-content: center;
}

.width-30 {
  flex: 1 1 50%;
  max-width: 400px;
  margin-right: 3%;
}

.input-space {
  margin: 2% 0;
}

.box-height {
  max-height: 80px;
}

.empty {
  height: 67px;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.toggle-off {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .form-container {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-direction: column-reverse;
    width: 100%;
  }
}

.card {
  flex-direction: column;
  padding: 30px;
  background-color: $white;
  border-radius: 15px;
}

.card-title {
  display: flex;
  justify-content: space-between;
}

.row-block-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-block {
  // max-width: 48%;
  max-width: 30%;
}

.checkbox {
  display: flex;
}

.base-form-title {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.03em;
}

.is-disabled-true ::v-deep textarea {
  cursor: not-allowed;
}

.company-term {
  display: flex;
  justify-content: center;

  .inner-container {
    width: 50%;

    .spacing {
      margin-top: 30px;
      margin-bottom: 5%;
    }

    .justify-right {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep .input-space.description {
  margin-top: 20px;
}

::v-deep input[disabled],
::v-deep input:disabled,
::v-deep textarea[disabled] {
  cursor: not-allowed;
  background-color: $blue-dull !important;
}
</style>
