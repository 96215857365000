import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { CompanyTerm } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getCompanyTerms(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<CompanyTerm>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return httpService.get(`https://${host}/company-terms?${query}`)
  },
  createCompanyTerms(payload: CompanyTerm): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companyTerms`
    return httpService.post(url, payload)
  },
  editCompanyTerms(payload: CompanyTerm): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companyTerms`
    return httpService.patch(url, payload)
  },
  getCompanyTerm(id: number): Promise<AxiosResponse<CompanyTerm>> {
    const host = baseUrl()
    const url = `https://${host}/companyTerms/${id}`
    return httpService.get(url)
  },
  deleteCompanyTerm(id: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companyTerms/${id}`
    return httpService.delete(url)
  },
  setDefaultCompanyTerms(id: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companyTerms/default/${id}`
    return httpService.patch(url, null)
  },
}
